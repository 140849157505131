<template>
  <secondary-page-layout :title="$t('language')" :displayedNavigation="true">
    <div class="px-3">
      <div class="primary-block primary-nav-list">
        <h3 class="title">{{$t('language')}}</h3>
        <div class="nav-item" v-for="(item, index) in langs" :key="index">
          <label :for="'lang-' + item.id" class="primary-text">{{ item.name }}</label>
          <input type="radio" v-model="language" @change="handleLang" class="w-100" :id="'lang-' + item.id" :value="item.id">
        </div>
      </div>
    </div>
  </secondary-page-layout>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
  name: "LanguageChange",
  data() {
    return {
      langs: [
        {name: this.$i18n.t('langRus'), id: 'ru',},
        {name: this.$i18n.t('langKaz'), id: 'kk',},
        {name: this.$i18n.t('langEng'), id: 'en',},
      ],
      language: null
    }
  },
  computed: {
    ...mapGetters(['selectedLanguage'])
  },
  mounted() {
    this.language = this.selectedLanguage;
  },
  methods: {
    ...mapActions(['getLanguage']),
    handleLang(item) {
      let id = item.target.value;
      this.getLanguage(id);
      location.replace('/');
    }
  }
}
</script>

<style lang="scss" scoped>
.nav-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  label {
    flex-grow: 1;
  }
}
</style>
